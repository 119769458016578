import { Link } from "react-router-dom";
import LightLogoImage from "../../images/Logo_files-03-removebg-preview (1).png"
export default function Logo({handleClick}) {
  return (
    <Link to="/" onClick={(e)=>{
      handleClick(e.currentTarget.href)
    }} className="block" aria-label="Cruip">
      <img className="h-32 w-40" src={LightLogoImage} alt="lightlogo" />
    </Link>
  )
}
