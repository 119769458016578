import { Link } from "react-router-dom";
import DigitalAndBroadCastingServiceImg from '../../images/services/DigitalBroadcastingServiceImg.jpg';
import ImageComponent from "../utils/ImageComponent";
export default function BroadCastingAndDigitalService({ handleClick }) {
  return (
    <div className="relative isolate font-family-poppins overflow-hidden bg-gray-900 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden"></div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Digital, and Broadcasting Services
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-100">
                At Goleador Sport Consulting, we offer a comprehensive suite of
                media, digital, and broadcasting services to enhance the
                visibility and reach of sports properties across various
                platforms. Leveraging our expertise and industry connections, we
                provide tailored solutions that maximize exposure, engagement,
                and revenue generation in the dynamic world of sports.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          {/* <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src={DigitalAndBroadCastingServiceImg}
            alt="image Broadcasting & digital service"
          /> */}
          <ImageComponent 
              className={"w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"}
              src={DigitalAndBroadCastingServiceImg}
              alt={"image Broadcasting & digital service"}
              height={600}
           />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-100 lg:max-w-lg">
              <ul className="mt-8 space-y-8 text-gray-100">
                <li className="flex gap-x-3">
                  {/* <CloudArrowUpIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    <strong className="font-semibold text-white">
                      Media Rights Distribution :{" "}
                    </strong>
                    We specialize in negotiating and distributing media rights
                    for sports events and leagues to television networks,
                    digital platforms, and other media outlets. Our strategic
                    partnerships and agreements ensure maximum exposure and
                    revenue generation for sports properties, helping them reach
                    global audiences and maximize their commercial potential.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <LockClosedIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    <strong className="font-semibold text-white">
                      Digital Content Creation :{" "}
                    </strong>
                    Our team creates engaging digital content tailored to sports
                    properties' target audiences, including highlights,
                    interviews, behind-the-scenes footage, and interactive
                    features. We leverage cutting-edge technology and creative
                    storytelling to enhance fan engagement and strengthen brand
                    visibility across digital platforms.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <ServerIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    <strong className="font-semibold text-white">
                      Digital Broadcasting :{" "}
                    </strong>
                    Goleador Sport Consulting assists sports properties in
                    leveraging digital broadcasting platforms to reach global
                    audiences and engage fans through live streaming, on- demand
                    content, and interactive experiences. We develop customized
                    digital broadcasting strategies to optimize viewership and
                    monetization opportunities, ensuring maximum impact and ROI.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <ServerIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    <strong className="font-semibold text-white">
                      Broadcasting Rights Management :{" "}
                    </strong>
                    We manage broadcasting rights for sports properties,
                    including negotiating contracts, scheduling broadcasts, and
                    ensuring compliance with licensing agreements. Our team
                    works closely with broadcasters to deliver high-quality
                    content to audiences worldwide, maximizing exposure and
                    revenue potential for sports properties.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <ServerIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    <strong className="font-semibold text-white">
                      Sponsorship Activation :{" "}
                    </strong>
                    Goleador Sport Consulting activates sponsorships across
                    digital and broadcasting platforms to maximize brand
                    exposure and engagement. We integrate sponsor messaging and
                    branding into digital content, broadcasts, and other media
                    channels, enhancing sponsor visibility and delivering
                    value-added opportunities for brands.
                  </span>
                </li>
              </ul>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-white">
                Contact Us
              </h2>
              <p className="mt-6">
                Ready to enhance the visibility and reach of your sports
                property through media, digital, and broadcasting services?{" "}
                <Link
                  onClick={(e) => {
                    window.scrollTo(0, 0);
                    handleClick(e.currentTarget.href);
                  }}
                  to={"/contact-us"}
                  className="text-indigo-600"
                >
                  Contact
                </Link>{" "}
                Goleador Sport Consulting today to learn more about how our
                tailored solutions can help you achieve your goals and drive
                success in the dynamic world of sports.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
