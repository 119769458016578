import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function ContactUs() {
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [companyError, setCompanyError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSubmit, setisSubmit] = useState(false);
  useEffect(() => {
        setLastNameError(lastName === '' ? 'Last name required' : '');
  }, [lastName]);
  
  useEffect(() => {
    setFirstNameError(firstName === '' ? 'First name required' : '');
  }, [firstName]);
  
  useEffect(() => {
    setEmailError(email === '' ? 'Email required' : !email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? 'Invalid email' : '');
  }, [email]);
  
  useEffect(() => {
    setCompanyError(company === '' ? 'Company name required' : '');
  }, [company]);
  
  useEffect(() => {
    setPhoneNumberError(phoneNumber === '' ? 'Phone number required' : !phoneNumber.match(/^(\+?\d{1,3}[- ]?)?\d{10}$/) ? 'Invalid phone number' : '');
  }, [phoneNumber]);
  
  useEffect(() => {
    setMessageError(message === '' ? 'Message required' : '');
  }, [message]);
  // useEffect(()=>{
  //     axios.get("http://acquagatta.store/posts").then((response)=>console.log(response.data));
  // }, [])
  const handleSubmit = async (e) => {
    e.preventDefault();
    setisSubmit(true);
    if (lastNameError === '' && firstNameError === '' && emailError === '' && companyError === '' && phoneNumberError === '' && messageError === '') {
        setLoading(true);
        try {
            const config = {
                SecureToken : "73f2767d-8187-4f1b-989c-444fe74cb185",
                To : 'contact@goleadorsport.com',
                From : "contact@goleadorsport.com",
                Subject : `Goleador Sport Consulting | New message from ${lastName} ${firstName}`,
                Body : `<p>Hello Mehdi Farhan,</p>
                <p>You got a new message from ${lastName} ${firstName}:</p>
                <p><b>Email: </b> ${email} :</p><br>
                <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">
                    ${message}
                </p>
                
                <p>
                 <b> Phone: </b> ${phoneNumber}  <br>
                 <b> Company: </b> ${company}  <br>
                </p>
                <p>
                  Best wishes,<br>Goleador Sport Consulting
                </p>  
                `
            }

            if(window.Email){
                await window.Email.send(config)
            }
            // const infos = { lastName, firstName, email, company, phoneNumber, message };
            // const { messageResponse } = await axios.post('/sendMail', infos);
            setLastName('');
            setFirstName('');
            setEmail('');
            setCompany('');
            setPhoneNumber('');
            setMessage('');
            toast.success('Your contact information has been successfully sent!');
        } catch (error) {
            toast.error(error.message);
        }finally{
            setLoading(false);
            setisSubmit(false);
        }
    }
  }
  return (
    <>
    <ToastContainer />
    <div className="isolate bg-gray-900 px-6 py-24 sm:py-32 lg:px-8 backImg">
      <div
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        aria-hidden="true"
      >
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
        </div>
      <div className='inset-0 bg-gray-800 rounded-lg p-8 mx-auto max-w-xl'>
        
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl text-left font-bold tracking-tight text-white sm:text-4xl">Contact Us</h2>
        <p className="mt-2 text-left text-lg leading-8 text-gray-200">
            Do you have a project in mind or need assistance with our services? We're here to help! Get in touch
            with our team to discuss your requirements, request a quote, or schedule a consultation. We value your 
            interest and aim to provide excellent customer service.
        </p>
      </div>
      <form onSubmit={handleSubmit} method="POST" className="mx-auto lg:mt-4 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-white">
              First name
            </label>
            <div className="mt-2.5">
              <input
                onChange={(e)=>setFirstName(e.target.value)}
                type="text"
                value={firstName}
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-transparent"
              />
              {firstNameError  && isSubmit && <span className='text-red-600 font-semibold'> {firstNameError}</span>}
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-white">
                Last name
            </label>
            <div className="mt-2.5">
              <input
                onChange={(e)=>setLastName(e.target.value)}
                type="text"
                value={lastName}
                name="last-name"
                id="last-name"
                autoComplete="family-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-transparent"
              />
              {lastNameError  && isSubmit && <span className='text-red-600 font-semibold'> {lastNameError}</span>}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="company" className="block text-sm font-semibold leading-6 text-white">
              Company
            </label>
            <div className="mt-2.5">
                <input
                  value={company}
                  onChange={(e)=>setCompany(e.target.value)}
                  type="text"
                  name="company"
                  id="company"
                  autoComplete="organization"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-transparent"
                />
                {companyError  && isSubmit && <span className='text-red-600 font-semibold'> {companyError}</span>}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-white">
              Email
            </label>
            <div className="mt-2.5">
              <input
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                type="text"
                name="email"
                id="email"
                autoComplete="email"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-transparent"
              />
                {emailError  && isSubmit && <span className='text-red-600 font-semibold'> {emailError}</span>}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-white">
                Phone number
            </label>
            <div className="relative mt-2.5">
              <input
                  value={phoneNumber}
                  onChange={(e)=>setPhoneNumber(e.target.value)}
                  type="text"
                  name="phone-number"
                  id="phone-number"
                  autoComplete="tel"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-transparent"
              />
              {phoneNumberError && isSubmit && <span className='text-red-600 font-semibold'> {phoneNumberError}</span>}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-white">
              Message
            </label>
            <div className="mt-2.5">
              <textarea
                  value={message}
                  onChange={(e)=>setMessage(e.target.value)}
                  name="message"
                  id="message"
                  rows={4}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-transparent"
                  defaultValue={''}
              />
              {messageError && isSubmit && <span className='text-red-600 font-semibold'> {messageError}</span>}
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            disabled={!loading ? false : true}
            className={`block ${!loading ? "" : "opacity-20"} w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
          >
            {
                !loading ? "Let's talk" : "Sending informations.."
            }
            
          </button>
        </div>
      </form>
      </div>
    </div>
    
    </>
  )
}
