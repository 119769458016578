import React from 'react';
import { Link } from 'react-router-dom';
import ServicesLink from './ServicesLink';
// import { Typography, Card, CardContent, List, ListItem } from '@mui/material';

export default function SimpleMenuNav({handleClick}) {



  return (
      <div className="flex justify-center w-3/4 items-center">
        <div className="w-full flex-col justify-center p-2">
          <ul className='flex justify-between items-center'>
            <Link onClick={(e)=>{
                handleClick(e.currentTarget.href)
                window.scrollTo(0, 0);
            }}  to={"/"} className='pt-2'><li className="mb-2 hover:text-indigo-400 transition duration-300 ease-in-out font-semibold text-white text-md font-family-poppins">Home</li></Link>
            <Link onClick={(e)=>{
                handleClick(e.currentTarget.href)
                window.scrollTo(0, 0);
            }}  to={"/about-us"} className='pt-2'><li className="mb-2 hover:text-indigo-400 transition duration-300 ease-in-out font-semibold text-white text-md font-family-poppins">About Us</li></Link>
            <ServicesLink handleClick={handleClick} />
            <Link onClick={(e)=>{
                handleClick(e.currentTarget.href)
                window.scrollTo(0, 0);
            }} to={"/gallery"} className='pt-2 '><li className="mb-2 hover:text-indigo-400 transition duration-300 ease-in-out font-semibold text-white text-md font-family-poppins">Gallery</li></Link>
            <Link onClick={(e)=>{
                handleClick(e.currentTarget.href)
                window.scrollTo(0, 0);
            }} to={"/contact-us"} className='pt-2 '><li className="mb-2 hover:text-indigo-400 transition duration-300 ease-in-out font-semibold text-white text-md font-family-poppins">Contact</li></Link>
          </ul>
        </div>
      </div>
  );
}