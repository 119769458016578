import { useState, useEffect } from "react";
import { Blurhash } from "react-blurhash";
export default function ImageComponent({src, className, alt, height}){
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setImageLoaded(true);
        }
        img.src = src;
    }, [src]);
    return (
        <>
            {!imageLoaded && (
                <Blurhash
                    hash="LWGl0KR*KOXl4-RjrXsD~XR*N^o}"
                    width={"100%"}
                    height={height ? height : 300}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                />
            )

            }
            {
                imageLoaded && (
                    <img 
                        src={src} 
                        alt={alt} 
                        className={className}
                    />
                )
            }
        </>
        
    )
}