import { useState, useEffect } from 'react'

import Logo from './logo'
import MobileMenu from './mobile-menu'
import SimpleMenuNav from '../utils/megaMenu'

export default function Header({handleClick}) {

  const [top, setTop] = useState(true)

  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true)
  }  

  useEffect(() => {
    scrollHandler()
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [top])

  return (
    <header className={`fixed w-full flex z-9 items-center h-24 rounded-b-xl z-30 bg-transparent backdrop-filter backdrop-blur-2xl bg-opacity-30 transition duration-300 ease-in-out`}>
      <div className="w-full mx-auto px-5 sm:px-6">
        <div className="flex w-full items-center justify-between h-16 md:h-20">

          <div className="shrink-0 mr-4">
            <Logo handleClick={handleClick} />
          </div>
          <nav className="hidden md:flex w-5/6 md:justify-around">
            <SimpleMenuNav handleClick={handleClick} />
          </nav>
          <MobileMenu handleClick={handleClick} />
        </div>
      </div>
    </header>
  )
}
