import { Link } from 'react-router-dom'
import BackGradient from '../utils/BackGradient';
import RightHoldersImg from '../../images/services/RightHoldersImg.jpeg';
import ImageComponent from '../utils/ImageComponent';

export default function ForRightHolders({ handleClick }) {
  return (
    <div className="font-family-poppins backImg relative isolate overflow-hidden bg-gray-900 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        {/* <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg> */}
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl"> For Right Holders</h1>
              <p className="mt-6 text-xl leading-8 text-gray-100">
                    At Goleador Sport Consulting, we offer a comprehensive suite of services tailored to 
                    meet the diverse needs of our clients in the sports industry. Our expertise spans across 
                    various areas, enabling us to provide tailored solutions that drive success and maximize 
                    the commercial potential of sports organizations.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            {/* <img
                className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                src={PlaceHolderImg}
                alt="image for right holder"
            /> */}
            
            <ImageComponent 
                className={"w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"}
                src={RightHoldersImg}
                alt={"image Broadcasting & digital service"}
                height={600}
            />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-100 lg:max-w-lg">
              <ul className="mt-8 space-y-8 text-gray-100">
                <li className="flex gap-x-3">
                  {/* <CloudArrowUpIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> */}
                  <span>
                        <strong className="font-semibold text-white">Marketing Consultancy : </strong> 
                        We provide strategic marketing advice and consultancy services to sports organizations, 
                        helping them develop and implement effective marketing strategies. From brand 
                        positioning and audience targeting to campaign planning and execution, we leverage our 
                        expertise to enhance brand visibility, engage fans, and drive business growth. 
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> */}
                  <span>
                        <strong className="font-semibold text-white">Sponsorship Sales : </strong> 
                        Our team excels in securing sponsorship deals for sports organizations, helping them 
                        establish mutually beneficial partnerships with leading brands. We identify potential 
                        sponsors, negotiate sponsorship agreements, and activate sponsorship assets to 
                        enhance brand visibility and drive revenue growth.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <ServerIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> */}
                  <span>
                        <strong className="font-semibold text-white">Maximizing Commercial Potential :</strong>
                        Goleador Sport Consulting unlocks the commercial potential of your sports organization 
                        through strategic marketing, asset restructuring and securing lucrative partnerships. 
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <ServerIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> */}
                  <span>
                        <strong className="font-semibold text-white">Event Management : </strong>
                        Our event management services cover all aspects of organizing and managing sports 
                        events, from venue selection and logistics planning to ticket sales, hospitality services, 
                        and marketing and promotion. We ensure seamless execution and memorable 
                        experiences that leave a lasting impression on attendees and stakeholders.
                  </span>
                </li>
              </ul>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-white">Contact Us</h2>
              <p className="mt-6">
                    At Goleador Sport Consulting, we are committed to delivering exceptional results 
                    tailored to your needs. Let us partner with you to achieve your goals and elevate your 
                    sport organization. <Link onClick={(e) => {
                        window.scrollTo(0, 0) ;
                        handleClick(e.currentTarget.href);
                    }} to={"/contact-us"} className='text-indigo-600'>Contact us</Link> today to discuss how we can help you succeed.
              </p>
            </div>
          </div>
        </div>
      </div>
      <BackGradient />
    </div>
  )
}
