import BackGradient from "./utils/BackGradient";
export default function Hero() {
  return (
    <div className="relative sm:top-32 lg:top-0 isolate overflow-hidden bg-gray-900 py-16 sm:py-24 lg:py-32 backImg">
      <div className="mx-auto mt-16 lg:mt-2 max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 style={{fontFamily:"Poppins"}} className="text-6xl lg:text-8xl md:text-8xl font-bold tracking-wide text-white sm:text-3xl">Goleador Sport Consulting</h2>
            <p className="mt-8 text-2xl leading-8 text-gray-300 font-family-poppins">
                Be in the Heart of the Game with Goleador
            </p>
          </div>
          <div className='hidden md:block'>
             <img src={"images/cubes-hero-e376bb03.png"} className="rounded-lg" alt="img" />
          </div>
        </div>
      </div>
      <BackGradient />
    </div>
    
  )
}