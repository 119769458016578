
import { Link } from "react-router-dom";
import BrandsImg from '../../images/services/brandsImg.jpeg';
import ImageComponent from "../utils/ImageComponent";

export default function ForBrands({ handleClick }) {
  return (
    <div className="font-family-poppins relative isolate overflow-hidden bg-gray-900 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden"></div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                For Brands
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-100">
                At Goleador Sport Consulting, we specialize in providing
                comprehensive sports marketing solutions tailored to meet the
                unique needs of brands looking to enhance their presence within
                the sports industry. Our range of services is designed to
                maximize brand visibility, engagement, and return on investment
                in the dynamic world of sports.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          
          <ImageComponent 
              className={"w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"}
              src={BrandsImg}
              alt={"image For brands"}
              height={600}
           />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-100 lg:max-w-lg">
              <ul className="mt-8 space-y-8 text-gray-100">
                <li className="flex gap-x-3">
                  {/* <CloudArrowUpIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    <strong className="font-semibold text-white">
                      Marketing Partnerships : {" "}
                    </strong>
                    We facilitate marketing partnerships between brands and
                    sports properties, helping them collaborate on marketing
                    campaigns, promotions, and activations to reach a broader
                    audience and drive brand awareness. From co-branded
                    initiatives to joint marketing efforts, we work closely with
                    brands and sports properties to create mutually beneficial
                    partnerships that deliver results.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <LockClosedIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    <strong className="font-semibold text-white">
                      Sponsorship Opportunities : {" "}
                    </strong>
                    We help brands identify and secure strategic sponsorship
                    opportunities within the sports industry, including
                    partnerships with sports events, teams, athletes, and
                    venues. Through our extensive network and industry
                    expertise, we connect brands with the right opportunities to
                    align with their marketing objectives and target audience.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <ServerIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    <strong className="font-semibold text-white">
                      Brand Integration : {" "}
                    </strong>
                    Our team assists brands in seamlessly integrating their
                    products or services into sports events, broadcasts, and
                    other platforms to increase brand visibility and engagement
                    with sports fans. Whether it's through product placements,
                    branded content, or experiential activations, we ensure that
                    brands make a memorable impact within the sports landscape.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <ServerIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    <strong className="font-semibold text-white">
                      Hospitality and Experiences : {" "}
                    </strong>
                    Goleador Sport Consulting offers hospitality and
                    experiential marketing opportunities for brands to engage
                    with customers and stakeholders through VIP experiences,
                    hospitality suites, and exclusive events at sports venues.
                    We create unforgettable experiences that leave a lasting
                    impression on guests while strengthening brand affinity and
                    loyalty.
                  </span>
                </li>
              </ul>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-white">
                Contact Us
              </h2>
              <p className="mt-6">
                Ready to take your brand to the next level in the world of
                sports marketing?&nbsp;
                <Link
                  onClick={(e) => {
                    window.scrollTo(0, 0);
                    handleClick(e.currentTarget.href);
                  }}
                  to={"/contact-us"}
                  className="text-indigo-600"
                >
                  Contact
                </Link>&nbsp;Goleador Sport Consulting today to learn more about how our
                tailored solutions can help elevate your brand and drive success
                in the dynamic world of sports.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
