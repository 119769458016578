import Services from "../components/services";
import Hero from "../components/hero";
function Home({ handleClick }) {
    return ( 
        <>
            <Hero />
            <Services handleClick={handleClick} />
        </>
     );
}

export default Home;