import './input.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Header from './components/ui/header';
import Footer from './components/ui/footer';
import ContactUs from './pages/contactUs';
import Portfolio from './pages/Portfolio';
import SpeedDialComponent from './components/utils/speedDial';
import AboutUs from './pages/AboutUs';
import LoadingBar from 'react-top-loading-bar';
import { useState } from 'react';
import Page404 from './pages/Page404';
import BroadCastingAndDigitalService from './components/servicesComponents/BroadCastingAndDigitalService';
import ForBrands from './components/servicesComponents/ForBrands';
import ForRightHolders from './components/servicesComponents/ForRightHolders';
// import { useTranslation } from "react-i18next";
// import { Translate } from "@google-cloud/translate";

function App() {
    const [progress, setProgress] = useState(0);

    const handleClick = async (href) => {
      setProgress(0);
      const response = await fetch(href);
      if (response.ok) {
          const reader = response.body.getReader();
          let receivedLength = 0;
          const contentLength = +response.headers.get('Content-Length');
          const chunks = [];
          while (true) {
              const { done, value } = await reader.read();
              if (done) break;
              chunks.push(value);
              receivedLength += value.length;
              const loaded = receivedLength / contentLength;
              setProgress(Math.min(loaded * 100, 100));
          }
          }else {
            console.error('Failed to fetch resource');
          }
    };
  return (
      <BrowserRouter basename='/'>
      <LoadingBar 
        color='lightblue'
        progress={progress}
      />
      <Header handleClick={handleClick} />
          <Routes>
              <Route path='/' element={ <Home handleClick={handleClick} /> } />
              <Route path='/contact-us' element={ <ContactUs /> } />
              <Route path='/gallery' element={ <Portfolio /> } />
              <Route path='/about-us' element={ <AboutUs /> } />
              <Route path='/for-right-holders' element={ <ForRightHolders handleClick={handleClick} /> } />
              <Route path='/for-brands' element={ <ForBrands handleClick={handleClick} /> } />
              <Route path='/digital-and-broadcasting-service' element={ <BroadCastingAndDigitalService handleClick={handleClick} /> } />
              <Route path='/*' element={ <Page404 handleClick={handleClick} /> } />
          </Routes>
          <SpeedDialComponent />
          <Footer handleClick={handleClick} />
      </BrowserRouter>
  );
}

export default App;
