import Slides from './Slides';
import Img1 from "../../images/Indrive/image00001.webp";
import Img2 from "../../images/Indrive/image00004.webp";
import Img3 from "../../images/Indrive/image00005.webp";
import Img4 from "../../images/Indrive/image00006.webp";
import Img5 from "../../images/Indrive/image00008.webp";
import ImageComponent from './ImageComponent';
const images = [
    {
      imageSrc: Img1,
      imageAlt: 'image indrive 1',
    },
    {
      imageSrc: Img2,
      imageAlt: 'image indrive 2',
    },
    {
      imageSrc: Img3,
      imageAlt: 'image indrive 3',
    },
    {
      imageSrc: Img4,
      imageAlt: 'image indrive 3',
    },
    {
      imageSrc: Img5,
      imageAlt: 'image indrive 3',
    },
]
const GalleryClient1 = () => {
    return ( 
        <>
            <div className="w-screen mx-auto px-5 py-2 lg:px-32 lg:pt-16">
                <div className="sm:max-w-xl lg:mt-2 mb-5">
                    <h1 className="text-2xl text-blue-400 font-semibold tracking-tight sm:text-2xl">
                        inDrive Sports Marketing Collaboration: Driving Success
                    </h1>
                </div>
                <div className="hidden lg:flex -m-1 w-full flex-wrap md:-m-2">
                    <div className="flex w-1/2 flex-wrap">
                        <div className="w-1/2 p-1 rounded-lg md:p-2">
                            
                            <ImageComponent 
                                src={Img3}
                                alt={"image.gallery1"}
                                className="h-full w-full  object-cover object-center"
                            />
                            {/* <LazyLoadImage
                                key="image1"
                                src={Img3}
                                effect='blur'
                                placeholderSrc={Img3}
                            /> */}
                            {/* <picture>
                                <source type='image/webp' />
                                    <img
                                        loading='lazy'
                                        alt="gallery1"
                                        className="block h-full w-full  object-cover object-center"
                                        src={Img3}
                                        srcSet={`${Img3} 1x, ${Img3} 2x`}
                                        role='presentation'
                                        sizes='(max-width: 800px) 100vw, 50vw'
                                        decoding='async'
                                        fetchPriority='high'
                                    />
                            </picture> */}
                        </div>
                        <div className="w-1/2 rounded-lg p-1 md:p-2">

                            
                            <ImageComponent 
                                src={Img1}
                                alt={"image.gallery2"}
                                className="h-full w-full object-cover object-center"
                            />
                            
                        </div>
                        <div className="w-full rounded-lg p-1 md:p-2">
                            <ImageComponent 
                                src={Img1}
                                alt={"image.gallery3"}
                                className="h-full w-full object-cover object-center"
                            />
                            {/* <LazyLoadImage
                                key="image3"
                                src={Img1}
                                effect='blur'
                                placeholderSrc={Img1}
                            /> */}
                            {/* <picture>
                                <source type='image/webp' />
                                    <img
                                        loading='lazy'
                                        alt="gallery3"
                                        className="block h-full w-full  object-cover object-center"
                                        src={Img5}
                                        srcSet={`${Img5} 1x, ${Img5} 2x`}
                                        role='presentation'
                                        sizes='(max-width: 800px) 100vw, 50vw'
                                        decoding='async'
                                        fetchPriority='high'
                                    />
                            </picture> */}
                        </div>
                    </div>
                    <div className="flex w-1/2 flex-wrap">
                    <div className="w-full p-1 rounded-lg md:p-2">
                            
                            <ImageComponent 
                                src={Img2}
                                alt={"image.gallery4"}
                                className="h-full w-full  object-cover object-center"
                            />
                            {/* <LazyLoadImage
                                key="image4"
                                src={Img2}
                                effect='blur'
                                placeholderSrc={Img2}
                            /> */}
                            {/* <picture>
                                <source type='image/webp' />
                                    <img
                                        loading='lazy'
                                        alt="gallery4"
                                        className="block h-full w-full  object-cover object-center"
                                        src={Img2}
                                        srcSet={`${Img2} 1x, ${Img2} 2x`}
                                        role='presentation' 
                                        sizes='(max-width: 800px) 100vw, 50vw'
                                        decoding='async'
                                        fetchPriority='high'
                                    />
                            </picture> */}
                    </div>
                    <div className="w-1/2 rounded-lg p-1 md:p-2">
                        
                            
                            <ImageComponent 
                                src={Img4}
                                alt={"image.gallery5"}
                                className="h-full w-full  object-cover object-center"
                            />
                            {/* <LazyLoadImage
                                key="image5"
                                src={Img4}
                                effect='blur'
                                placeholderSrc={Img4}
                            /> */}
                            {/* <picture>
                                <source type='image/webp' />
                                    <img
                                        loading='lazy'
                                        alt="gallery5"
                                        className="block h-full w-full  object-cover object-center"
                                        src={Img4}
                                        srcSet={`${Img4} 1x, ${Img4} 2x`}
                                        role='presentation' 
                                        sizes='(max-width: 800px) 100vw, 50vw'
                                        decoding='async'
                                        fetchPriority='high'
                                    />
                            </picture> */}
                    </div>
                    <div className="w-1/2 rounded-lg p-1 md:p-2">
                            <ImageComponent 
                                src={Img3}
                                alt={"image.gallery6"}
                                className="h-full w-full  object-cover object-center"
                            />
                            {/* <LazyLoadImage
                                key="image6"
                                src={Img3}
                                effect='blur'
                                placeholderSrc={Img3}
                            /> */}
                            {/* <picture>
                                <source type='image/webp' />
                                    <img
                                        loading='lazy'
                                        alt="gallery6"
                                        className="block h-full w-full  object-cover object-center"
                                        src={Img3}
                                        srcSet={`${Img3} 1x, ${Img3} 2x`}
                                        role='presentation' 
                                        sizes='(max-width: 800px) 100vw, 50vw'
                                        decoding='async'
                                        fetchPriority='high'
                                    />
                            </picture> */}
                    </div>
                    </div>
                </div>
                
                <div className='block lg:hidden'>
                    <Slides images={images} />
                </div>
            </div>
        </>
     );
}
 
export default GalleryClient1;